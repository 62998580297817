import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'
import { ListToolbar } from '../list'
// ----------------------------------------------------------------------

const SpacesStyle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: -20
}))

const HeaderStyle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

const TitleStyle = styled('span')(({ theme }) => ({
  marginBottom: theme.spacing(1)
}))

const SubtitleStyle = styled('span')(({ theme }) => ({
  color: theme.palette.grey[800],
  fontSize: theme.typography.pxToRem(14),
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular
}))

const ToolBarStyle = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(5)
}))

export default function SpacesHeader({ onFilterSearch, filterSearch, onOpenCreateSpace }) {
  return (
    <SpacesStyle>
      <HeaderStyle>
        <TitleStyle>Spaces</TitleStyle>
        <SubtitleStyle>Here are all the spaces you have access to.</SubtitleStyle>
      </HeaderStyle>
      <Button
        sx={{ boxShadow: 'none' }}
        type="submit"
        variant="contained"
        color="secondary"
        onClick={onOpenCreateSpace}
        id="createNewSpaceButton"
      >
        Create a new space
      </Button>
      <ToolBarStyle>
        <ListToolbar filterName={filterSearch} onFilterSearch={onFilterSearch} placeholder="Search spaces..." />
      </ToolBarStyle>
    </SpacesStyle>
  )
}

SpacesHeader.propTypes = {
  onFilterSearch: PropTypes.func,
  filterSearch: PropTypes.string,
  onOpenCreateSpace: PropTypes.func
}
