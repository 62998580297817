import api from './api'
import assetAPI from './assetAPI'

// get
export const getRoomNotifies = (userId) => api.get(`users/${userId}/rooms/notifies`)
export const getRoomById = (roomId) => api.get(`rooms/${roomId}`)
export const getRoomAnalytics = (roomId) => api.get(`/analytics/rooms/${roomId}`)
export const getActives = () => api.get(`rooms/active`)
export const getRoomAnalyticsTotalParticipants = (roomId) => api.get(`/analytics/rooms/${roomId}/total_participants`)
export const getRoomAnalyticsTotalNoOfConvos = (roomId) => api.get(`/analytics/rooms/${roomId}/no_of_convos`)
export const getRoomAnalyticsTotalMatches = (roomId) => api.get(`/analytics/rooms/${roomId}/total_matches`)
export const getRoomAnalyticsNpsScore = (roomId) => api.get(`/analytics/rooms/${roomId}/nps_score`)
export const getRoomAnalyticsTotalConnections = (roomId) => api.get(`/analytics/rooms/${roomId}/total_connections`)
export const getRoomAnalyticsTotalMinutesOfConvo = (roomId) =>
  api.get(`/analytics/rooms/${roomId}/total_minutes_of_convo`)
export const getRoomAnalyticsAvgMatchesPerUser = (roomId) => api.get(`/analytics/rooms/${roomId}/avg_matches_per_user`)
export const getRoomAnalyticsAvgTimeInConvo = (roomId) => api.get(`/analytics/rooms/${roomId}/avg_time_in_convo`)
export const getRoomAnalyticsConversationPartners = (roomId) =>
  api.get(`/analytics/rooms/${roomId}/conversation_partners`)
export const getRoomAnalyticsLeaderboard = (roomId) => api.get(`/analytics/rooms/${roomId}/leader_board`)

// put
export const deleteSpaceRoom = (roomId, roomInfo) => api.put(`rooms/${roomId}`, roomInfo)
export const updateRoom = (roomId, roomData) => api.put(`rooms/${roomId}`, roomData)

// post
export const addSpaceRoom = (payload) => api.post('/rooms', payload)
export const cloneRoom = (roomId) => api.post(`rooms/${roomId}/clone`)

// delete
export const removeSpaceRoomVideo = (roomId) => api.delete(`/rooms/${roomId}/videos`)

// ASSET API
export const addSpaceRoomVideo = (payload, roomId) => assetAPI.post(`/rooms/${roomId}/videos`, payload)
