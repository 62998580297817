import { createSlice } from '@reduxjs/toolkit'
// services
import { getAllCountries, getCities } from '../../services/countries'
// utils
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {},
  citiesData: {}
}

const slice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getCountriesSuccess(state, action) {
      const country = action.payload[0]
      state.isLoading = false
      state.isSuccess = true
      state.data = country
    },

    getCitiesDataSuccess(state, action) {
      const cities = action.payload[0]
      state.isUpdating = false
      state.isLoading = false
      state.citiesData = cities
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getCountries() {
  const { startLoading, hasError, getCountriesSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getAllCountries()
      dispatch(getCountriesSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getCitiesByCountry(country, searchCity, pageNo) {
  const { startLoading, hasError, getCitiesDataSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getCities(country, searchCity, pageNo)
      dispatch(getCitiesDataSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}
