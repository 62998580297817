import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// material
import { Stack, Divider, Button, Typography, Switch, OutlinedInput, Box, Alert } from '@mui/material'
import { useTheme } from '@mui/styles'
import { DeleteOutlined } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
// components
import { DialogAnimate } from '../animate'
import ConfirmationDialog from '../ConfirmationDialog'

const HEADER_TEXT = {
  manageSpaceHeader: 'Manage Space',
  manageSpaceText: 'Create a space for your company, separate teams or events.'
}

const SPACE_MANAGE_TEXT = {
  spaceControl: 'Space controls',
  appliesToAllRooms: 'Applies to all rooms in this space.',
  allowShareScreen: 'Allow guests to share their screen'
}

const SPACE_ERROR_MESSAGE = {
  spaceErrorAlert: 'Name length must be less than or equal to 256 characters long'
}

const MAX_LENGTH = 256

SpaceManager.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  space: PropTypes.object,
  onSave: PropTypes.func,
  toggleScreenShare: PropTypes.bool,
  onToggle: PropTypes.func,
  isShareScreen: PropTypes.bool,
  onDisable: PropTypes.func,
  deleteSpaceOpen: PropTypes.bool,
  setDeleteSpaceOpen: PropTypes.func
}

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  paddingBottom: '0'
}))

const TitleStyle = styled('span')(({ theme }) => ({
  marginBottom: '4px',
  fontWeight: 'bold',
  color: theme.palette.grey[800],
  fontSize: '18px'
}))

const SubtitleStyle = styled('span')(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: theme.typography.pxToRem(14),
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular
}))
export default function SpaceManager({
  open,
  onClose,
  space,
  onSave,
  toggleScreenShare,
  onToggle,
  onDisable,
  isShareScreen,
  deleteSpaceOpen,
  setDeleteSpaceOpen
}) {
  const [spaceName, setSpaceName] = useState('')

  const theme = useTheme()
  const CustomButton = styled(Button)`
    &.Mui-disabled {
      background-color: ${theme.palette.buttons.defaultBackground};
      color: ${theme.palette.buttons.defaultText};
      opacity: 0.5;
    }
  `

  useEffect(() => {
    setSpaceName(space ? space.name : '')
  }, [space])

  const handleClose = () => onClose()

  const handleChange = (e) => {
    setSpaceName(e.target.value)
  }
  const handleDeleteSpace = () => {
    setDeleteSpaceOpen(true)
  }
  const handleSave = () => onSave({ id: space.id, name: spaceName })

  const deleteHandleClose = () => {
    setDeleteSpaceOpen(false)
  }

  return (
    <DialogAnimate open={open} onClose={onClose} fullWidth maxWidth="md">
      <Stack>
        <Stack display="flex" flexDirection="row">
          <Stack width="50%">
            <Stack pl={2} mt={2}>
              <HeaderStyle>
                <TitleStyle>{HEADER_TEXT.manageSpaceHeader}</TitleStyle>
                <SubtitleStyle>{HEADER_TEXT.manageSpaceText}</SubtitleStyle>
              </HeaderStyle>
            </Stack>
            <Stack sx={{ marginTop: '24px', marginLeft: '24px' }} width={430}>
              <OutlinedInput
                sx={{ width: 387, fontWeight: 600 }}
                value={spaceName}
                placeholder="Give your space a name..."
                onChange={handleChange}
              />
            </Stack>
            {spaceName?.length > MAX_LENGTH && (
              <Box width={379} sx={{ position: 'absolute', top: 200, left: 25 }}>
                <Alert severity="error">{SPACE_ERROR_MESSAGE.spaceErrorAlert}</Alert>
              </Box>
            )}
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ height: '404px' }} />
          <Stack
            pl={2}
            mt={2}
            width="50%"
            style={{
              flex: 1,
              fontSize: '16px'
            }}
          >
            <HeaderStyle>
              <TitleStyle>{SPACE_MANAGE_TEXT.spaceControl}</TitleStyle>
              <SubtitleStyle>{SPACE_MANAGE_TEXT.appliesToAllRooms}</SubtitleStyle>
            </HeaderStyle>
            <Stack pl={1}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '19px' }}>
                <Typography
                  sx={
                    toggleScreenShare
                      ? { opacity: 0.5, color: theme.palette.grey[800] }
                      : { color: theme.palette.grey[800] }
                  }
                  variant="subtitle1"
                >
                  {SPACE_MANAGE_TEXT.allowShareScreen}
                </Typography>
                <Switch
                  id="manageToggleScreenShare"
                  sx={{ marginRight: '24px' }}
                  checked={!toggleScreenShare}
                  onChange={onToggle}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Divider />
          <Stack pt={2} px={3} pb={2} direction="row" justifyContent="space-between">
            <Button
              sx={{
                backgroundColor: theme.palette.buttons.destroyBackground,
                color: theme.palette.buttons.defaultText,
                border: `1px solid ${theme.palette.buttons.destroyBackground}`,
                '&:hover': { backgroundColor: theme.palette.buttons.destroyBackground }
              }}
              onClick={handleDeleteSpace}
              variant="outlined"
              id="deleteSpaceButton"
            >
              <DeleteOutlined sx={{ mr: 1, color: theme.palette.grey[0] }} />
              Delete space
            </Button>

            <ConfirmationDialog
              isOpen={deleteSpaceOpen}
              onClose={deleteHandleClose}
              onYes={onDisable}
              spaceName={space.name}
            />
            <Stack direction="row">
              <Button
                id="cancelSpaceButton"
                sx={{
                  color: theme.palette.secondary,
                  border: theme.palette.buttons.doneBorder
                }}
                variant="inherit"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <CustomButton
                id="saveSpaceButton"
                sx={{
                  color: theme.palette.secondary,
                  border: theme.palette.buttons.doneBorder,
                  boxShadow: 'none',
                  marginLeft: '24px'
                }}
                onClick={handleSave}
                variant="contained"
                disabled={
                  toggleScreenShare === isShareScreen &&
                  (!spaceName?.length || space.name === spaceName || spaceName.length > MAX_LENGTH)
                }
              >
                Save
              </CustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </DialogAnimate>
  )
}
