/* eslint-disable dot-notation */
import axios from 'axios'
// utils
import { getValue, saveValue, removeValue, KEYS } from '../utils/handleLocalStorage'
import { refreshToken } from './auth'
import { log } from '../utils/logger'

// ----------------------------------------------------------------------

const getHeader = () => {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache'
  }
  return headers
}

const api = axios.create({
  headers: getHeader(),
  baseURL: process.env.REACT_APP_BACKEND_URL
})

api.interceptors.request.use((config) => {
  const token = getValue(KEYS.TOKEN)
  if (token) {
    config.headers.Authorization = token
  }
  return config
})

api.interceptors.response.use(
  (res) => res.data,
  async (err) => {
    const originalConfig = err.config

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        // Access Token was expired
        originalConfig._retry = true
        log.warning('Token expired')
        try {
          const response = await refreshToken({ token: getValue(KEYS.REFRESH_TOKEN) })
          const { token } = response.response_objects
          saveValue(KEYS.TOKEN, token)
          api.defaults.headers.Authorization = token
          log.success('Token refreshed')
          return await api(originalConfig)
        } catch (_error) {
          if (_error.response && _error.response.data) {
            // Could not refresh token due to an error
            log.error({ message: 'Could not refresh token due to an error', _error })
            removeValue(KEYS.USER_ID)
            return Promise.reject(_error.response.data)
          }
          // Already trying to refresh token
          log.error({ message: 'Already trying to refresh token', _error })
          return Promise.reject(_error)
        }
      }

      if (err.response.status === 403 && err.response.data) {
        // I not 401 error then Logout
        removeValue(KEYS.USER_ID)
        log.error({ message: 'Error 403', err })
        return Promise.reject(err.response.data)
      }

      if (err.response.status === 400) {
        return Promise.reject(err.response.data)
      }
    }

    return Promise.reject(err)
  }
)

export default api
