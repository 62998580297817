import PropTypes from 'prop-types'
import { Dialog, DialogTitle, DialogContent, Button, Typography, Divider, Stack } from '@mui/material'
import { useTheme } from '@mui/styles'

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onYes: PropTypes.func,
  spaceName: PropTypes.string,
  content: PropTypes.string,
  title: PropTypes.string,
  btnBgColor: PropTypes.string
}
/**
 * This is a Shared Component used from 'CreateRoom.js' and 'CreateRoomsHeader.js'
 * @param  {bool} isOpen - a flag to control when the Dialog should Open
 * @param  {Callback} onClose - a callback to close this Dialog pop-up
 * @param  {Callback} onYes - a callback to handle onClick event of Yes button
 * @param  {String} content - content message to show in the popup
 * @param  {String} spaceName - to indicate the space name which is to be delete from manage space
 */
export default function ConfirmationDialog({ isOpen, onClose, onYes, content, spaceName, title, btnBgColor }) {
  const theme = useTheme()
  const confirmButtonBackgroundColor = btnBgColor || theme.palette.buttons.defaultBackground
  return (
    <Dialog open={isOpen}>
      <Stack width={383} spacing={5} justifyContent="space-between">
        <DialogTitle textAlign="center">
          {title && title}
          {!title && `Are you sure you want to ${spaceName ? 'delete this space?' : 'cancel?'}`}
        </DialogTitle>
        <DialogContent>
          <Typography textAlign="center">
            {spaceName ? `Everything in “${spaceName}” will be deleted forever.` : content}
          </Typography>
        </DialogContent>
        <Divider />
      </Stack>
      <Stack p={2} direction="row" justifyContent="space-between">
        <Button
          id="cancelDialogButton"
          variant="contained"
          color="inherit"
          onClick={onClose}
          sx={{ boxShadow: 'none' }}
        >
          {spaceName ? 'Cancel' : 'Nevermind'}
        </Button>
        <Button
          variant="contained"
          color="error"
          sx={{
            backgroundColor: confirmButtonBackgroundColor,
            boxShadow: 'none'
          }}
          onClick={onYes}
        >
          Yes
        </Button>
      </Stack>
    </Dialog>
  )
}
