import { createSlice } from '@reduxjs/toolkit'
// services
import { getSpaceRooms } from '../../services/space'
import {
  updateRoom,
  getRoomById,
  addSpaceRoom,
  addSpaceRoomVideo,
  getActives,
  cloneRoom,
  removeSpaceRoomVideo
} from '../../services/rooms'
// redux
import { refreshUserData } from './user'
import { refreshSpace, getSpace } from './space'
// utils
import { log } from '../../utils/logger'
import { saveValue, KEYS } from '../../utils/handleLocalStorage'

// ----------------------------------------------------------------------

export const SliceActions = {
  archive: 'archive',
  update: 'update',
  add: 'add',
  getAll: 'getAll',
  getOne: 'getOne',
  clone: 'clone'
}

const initialState = {
  isLoading: false,
  isUpdating: false,
  isArchiving: false,
  isAdding: false,
  isCloning: false,
  isSuccess: false,
  error: false,
  data: {},
  roomData: {},
  demoRoomData: {},
  activeRooms: [],
  clonedRoomId: null,
  successMessage: ''
}

const ROOMS_CONSTANTS = {
  createRoomSuccess: 'Room Created Successfully',
  editRoomSuccess: 'Room Updated Successfully',
  success: 'success'
}

const slice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // START UPDATING
    startUpdating(state) {
      state.isSuccess = false
      state.isUpdating = true
    },

    // START ARCHIVING
    startArchiving(state) {
      state.isSuccess = false
      state.isArchiving = true
    },

    // START CLONING
    startCloning(state) {
      state.isSuccess = false
      state.isCloning = true
    },

    // START ADDING
    startAdding(state) {
      state.isSuccess = false
      state.isAdding = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.isUpdating = false
      state.isArchiving = false
      state.isAdding = false
      state.error = action.payload
    },

    // SUCCESS MESSAGE -> STORE SUCCESS MESSAGE
    hasSuccessMessage(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.successMessage = action.payload
    },

    // SUCCESS -> STORE DATA
    getSpaceRoomsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    },

    // SUCCESS -> STORE DATA
    getRoomByIdSuccess(state, action) {
      state.isLoading = false
      state.isUpdating = false
      state.isAdding = false
      state.isSuccess = true
      const { rooms, room_ids: roomIds } = state.data
      const updatedRoom = action.payload.rooms[0]

      if (!rooms.length) {
        rooms[0] = updatedRoom
        roomIds.push(updatedRoom.id)
      } else {
        // We use map here to avoid the mutation of the original data
        // Flag is to verify whether this Room is updated or not
        let flag = false
        rooms.map((room, index) => {
          // Update the data (only if the room is already there)
          if (room.id === updatedRoom.id) {
            flag = true
            rooms[index] = updatedRoom
          } else if (index === rooms.length - 1 && !flag) {
            // Adding Room data only if the 'updatedRoom' is not in the list of Rooms
            rooms[index + 1] = updatedRoom
            roomIds.push(updatedRoom.id)
          }
          return room
        })
      }
      state.data.rooms = rooms
    },

    // SUCCESS -> REMOVE DATA
    archiveRoomSuccess(state, action) {
      state.isLoading = false
      state.isArchiving = false
      state.isSuccess = true
      const { rooms } = state.data
      const updatedRoom = action.payload

      // We use map here to avoid the mutation of the original data
      // Flag is to verify whether this Room is updated or not
      const newRooms = rooms.filter((room) => {
        // Update the data (only if the room is already there)
        if (room.id === updatedRoom.id) {
          return false
        }
        return true
      })
      state.data.rooms = newRooms
    },

    getRoomDataSuccess(state, action) {
      const room = action.payload.rooms[0]
      state.isUpdating = false
      state.isLoading = false
      state.roomData = room
    },

    getDemoRoomDataSuccess(state, action) {
      state.demoRoomData = action.payload
    },

    activeRoomsSuccess(state, action) {
      state.activeRooms = action.payload
    },

    reset(state) {
      state.data = {}
    },

    resetDemoRoom(state) {
      state.demoRoomData = {}
    },

    clonedRoomSuccess(state, action) {
      state.clonedRoomId = action.payload
    },

    resetClonedRoom(state) {
      state.clonedRoomId = null
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getRooms(spaceID) {
  const { startLoading, hasError, getSpaceRoomsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getSpaceRooms(spaceID)
      dispatch(getSpaceRoomsSuccess(data.response_objects))
    } catch (error) {
      const errorObj = { ...error, action: SliceActions.getAll }
      log.error(errorObj)
      dispatch(hasError(errorObj))
    }
  }
}

/**
 * Get a Room data by id from the API and updates the redux store with this latest data
 * @param {roomID} roomId the id of the room
 * @returns an async task that calls the API to get a room data by id
 */
export function getRoom(roomId) {
  const { startLoading, hasError, getRoomByIdSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomById(roomId)
      dispatch(getRoomByIdSuccess(data.response_objects))
    } catch (error) {
      const errorObj = { ...error, action: SliceActions.getOne }
      log.error(errorObj)
      dispatch(hasError(errorObj))
    }
  }
}

/**
 * Update a room with the information stored in the room object. A subset of a room
 * can be passed as a parameter if only few fields are needed to update
 * @param {room} room - Room data to be updated, must include the Room ID
 * @returns an async task that calls the API to update a room
 */
export function updateRoomInfo(room, video, deleteVideo) {
  const { hasError, startUpdating, hasSuccessMessage } = slice.actions
  let step = null
  return async (dispatch) => {
    dispatch(startUpdating())
    // Validate room information
    if (!room.id) {
      const error = { ...new Error('Room ID is mandatory'), action: SliceActions.update }
      dispatch(error)
    } else {
      try {
        step = 'Sending room info to endpoint'
        const roomData = { ...room, id: undefined, is_disabled: false }
        await updateRoom(room.id, roomData)
        if (video) {
          await addSpaceRoomVideo(video, room.id)
        }
        if (deleteVideo) {
          removeSpaceRoomVideo(room.id)
        }
        dispatch(getRoom(room.id))
        dispatch(hasError(''))
        dispatch(hasSuccessMessage(ROOMS_CONSTANTS.editRoomSuccess))
        dispatch(getActiveRooms())
      } catch (error) {
        const errorObj = { ...error, action: SliceActions.update }
        log.warning(step)
        log.info({ room })
        log.error(errorObj)
        dispatch(hasError(errorObj))
        dispatch(hasSuccessMessage(''))
      }
    }
  }
}

/**
 * Archive a room by disabling it and updating against the server
 * @param {room} room - Room data to be archived, must include the Room ID
 * @returns an async task that calls the API to archive a room
 */
export function archiveRoom(room) {
  const { hasError, archiveRoomSuccess, startArchiving, getSpaceRoomsSuccess } = slice.actions
  return async (dispatch) => {
    const data = {
      id: room.id,
      name: room.name,
      starts_on: room.starts_on,
      ends_on: room.ends_on,
      is_private: room.is_private,
      is_disabled: true
    }
    // Check if the room is private or not
    if (room.is_private) {
      const roomsDataById = await getRoomById(room.id)
      const roomsData = roomsDataById.response_objects.rooms[0]
      const entryCode = roomsData.entry_code
      data.entry_code = entryCode
    }
    dispatch(startArchiving())
    // Validate room information
    if (!room.id) {
      const error = { ...new Error('Room ID is mandatory'), action: SliceActions.archive }
      dispatch(hasError(error))
    } else {
      try {
        const roomData = { ...data, id: undefined }
        await updateRoom(room.id, roomData)
        dispatch(archiveRoomSuccess(room))
        const rooms = await getSpaceRooms(room.space_id)
        dispatch(getSpaceRoomsSuccess(rooms.response_objects))
        dispatch(getActiveRooms())
      } catch (error) {
        const errorObj = { ...error, action: SliceActions.archive }
        log.error({ room, errorObj, error })
        dispatch(hasError(errorObj))
      }
    }
  }
}
/** Adding Room Data, must need room id as response for uploading video
 * @param  {room} room - Room info to be added
 * @param  {FormData} video - Intro video to be upload via ASSET_API
 * @param  {userID} userID - current user ID to get rooms list updated on space model
 */
export function addRoom(room, video, userId) {
  const { hasError, startAdding, hasSuccessMessage } = slice.actions
  let step = 'New Room Data to endpoint'
  return async (dispatch) => {
    dispatch(startAdding())
    try {
      step = 'Room without Intro Video'
      const data = await addSpaceRoom(room)
      if (video) {
        step = 'Room with Intro Video'
        await addSpaceRoomVideo(video, data.response_objects.id)
      }
      dispatch(refreshUserData(userId))
      dispatch(getSpace(room.space_id))
      dispatch(getRoom(data.response_objects.id))
      dispatch(hasError(''))
      dispatch(hasSuccessMessage(ROOMS_CONSTANTS.createRoomSuccess))
      dispatch(getActiveRooms())
    } catch (error) {
      const errorObj = { ...error, action: SliceActions.add }
      log.error(step, room, errorObj)
      dispatch(hasError(errorObj))
      dispatch(hasSuccessMessage(''))
    }
  }
}

export function getRoomData(roomId) {
  const { getRoomDataSuccess, startLoading, hasError, hasSuccessMessage } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomById(roomId)
      dispatch(getRoomDataSuccess(data.response_objects))
      dispatch(hasError(''))
      dispatch(hasSuccessMessage(''))
    } catch (error) {
      log.error(error)
    }
  }
}

export function getActiveRooms() {
  const { activeRoomsSuccess, hasError } = slice.actions
  return async (dispatch) => {
    try {
      const data = await getActives()
      dispatch(activeRoomsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function reset() {
  const { reset } = slice.actions
  return async (dispatch) => {
    dispatch(reset())
  }
}

export function addDemoRoom(room) {
  const { getDemoRoomDataSuccess, hasError } = slice.actions
  let step = 'Adding new demo room'
  return async (dispatch) => {
    try {
      step = 'Inserting record'
      const spaceId = room.space_id
      const inserted = await addSpaceRoom(room)

      step = 'Getting demo room data'
      const data = await getRoomById(inserted.response_objects.id)

      step = 'Storing new demo room data into redux'
      dispatch(getDemoRoomDataSuccess(data.response_objects))

      step = 'Extract spaceId and userId from data'
      const { created_by: userId } = data.response_objects.rooms[0]

      step = 'Store spaceId in localStorage'
      saveValue(KEYS.SPACE_ID, spaceId)

      step = 'Dispatch refresh user'
      dispatch(refreshUserData(userId))

      step = 'Dispatch refresh space'
      dispatch(refreshSpace(spaceId))

      step = 'Dispatch refresh rooms'
      dispatch(getRooms(spaceId))
    } catch (error) {
      const errorObj = { error, action: SliceActions.add, step, room }
      log.error(errorObj)
      dispatch(hasError(errorObj))
    }
  }
}

export function resetDemoRoom() {
  const { resetDemoRoom } = slice.actions
  return async (dispatch) => {
    dispatch(resetDemoRoom())
  }
}

export function resetCloned() {
  const { resetClonedRoom } = slice.actions
  return async (dispatch) => {
    dispatch(resetClonedRoom())
  }
}

export function cloneSpaceRoom(roomId) {
  const { hasError, startCloning, clonedRoomSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startCloning())
    try {
      const clone = await cloneRoom(roomId)
      const clonedRoomId = clone.response_objects.id
      const data = await getRoomById(clonedRoomId)
      const clonedRoom = data.response_objects.rooms[0]
      const spaceId = clonedRoom.space_id
      const userId = clonedRoom.created_by
      const dispatches = []
      dispatches.push(dispatch(refreshUserData(userId)))
      dispatches.push(dispatch(refreshSpace(spaceId)))
      dispatches.push(dispatch(getRooms(spaceId)))
      Promise.all([...dispatches]).then(() => {
        dispatch(clonedRoomSuccess(clonedRoomId))
        dispatch(getActiveRooms())
      })
    } catch (error) {
      const errorObj = { ...error, action: SliceActions.clone }
      log.error(error)
      dispatch(hasError(errorObj))
    }
  }
}
