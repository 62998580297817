import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// slices
import userReducer from './slices/user'
import roomsReducer from './slices/rooms'
import notificationsReducer from './slices/notifications'
import spaceReducer from './slices/space'
import membersReducer from './slices/members'
import analyticsReducer from './slices/analytics'
import connectionsReducer from './slices/connections'
import topicsReducer from './slices/topics'
import questionsReducer from './slices/questions'
import memberReducer from './slices/member'
import memberConnectionsReducer from './slices/memberConnections'
import countriesReducer from './slices/countries'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}

const roomsPersistConfig = {
  key: 'rooms',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}

const membersPersistConfig = {
  key: 'members',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}

const analyticsPersistConfig = {
  key: 'members',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
}

const rootReducer = combineReducers({
  user: userReducer,
  rooms: persistReducer(roomsPersistConfig, roomsReducer),
  notifications: notificationsReducer,
  space: spaceReducer,
  members: persistReducer(membersPersistConfig, membersReducer),
  analytics: persistReducer(analyticsPersistConfig, analyticsReducer),
  connections: connectionsReducer,
  topics: topicsReducer,
  questions: questionsReducer,
  member: memberReducer,
  memberConnections: memberConnectionsReducer,
  countries: countriesReducer
})

export { rootPersistConfig, rootReducer }
