/* eslint sort-keys: "error" */
// Object properties are sorted alphabetically for easier identification

export const APP_PATHS = {
  CONNECTIONS: 'connections',
  HOME: 'home',
  INSIGHTS: 'insights',
  MEMBERS: 'members',
  PARTNER: 'partner',
  PROFILE: 'profile',
  ROOMS: 'rooms',
  ROOMS_CREATE: 'rooms/create',
  ROOMS_EDIT: 'rooms/:id/edit',
  ROOM_INSIGHTS: 'insights/room',
  SETTINGS: 'profile/edit',
  SPACES: 'spaces'
}

export const LINKS = {
  PRIVACY_POLICY: 'https://try.twine.nyc/privacy-policy',
  SUPPORT_DOCUMENTATION: 'https://help.twine.nyc/en/?utm_source=product&utm_medium=studio&utm_campaign=help_request',
  TERMS_OF_SERVICE: 'https://try.twine.nyc/terms-of-service'
}

export const DISPLAY_MESSAGES = {
  TIMEOUT: 5000
}

export const ROWS_PER_PAGE = 5
export const CONNECTION_LIST_ROWS_PER_PAGE = 10
export const SPACES_LIST_ROWS_PER_PAGE = 8
