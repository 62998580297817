import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
// components
import Spaces from './Spaces'
import SpaceHeader from './SpacesHeader'
import { DialogAnimate } from '../animate'
// constants
import { SPACES_LIST_ROWS_PER_PAGE } from '../../utils/constants'

SpacesModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loadSpace: PropTypes.func,
  spaces: PropTypes.array,
  onOpenManager: PropTypes.func,
  onOpenCreateSpace: PropTypes.func
}

export default function SpacesModal({ open, onClose, spaces, loadSpace, onOpenManager, onOpenCreateSpace }) {
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(SPACES_LIST_ROWS_PER_PAGE)
  const [records, setRecords] = useState([])

  useEffect(() => {
    setRecords(spaces || [])
  }, [spaces])

  useEffect(() => {
    if (spaces) {
      setRecords(spaces.filter((space) => space.name.toLowerCase().includes(filter)))
    }
  }, [spaces, filter])

  const handleFilterBySearch = (event) => {
    setPage(0)
    setFilter(event.target.value.toLowerCase())
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <DialogAnimate
      title={
        <SpaceHeader
          filterSearch={filter}
          onFilterSearch={handleFilterBySearch}
          onOpenCreateSpace={onOpenCreateSpace}
        />
      }
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <Spaces
        onCloseSpaces={onClose}
        records={records}
        loadSpace={loadSpace}
        rowsPerPage={rowsPerPage}
        page={page}
        setRowsPerPage={setRowsPerPage}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onOpenManager={onOpenManager}
      />
    </DialogAnimate>
  )
}
