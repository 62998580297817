import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, Link, Stack, Drawer, Typography, Button } from '@mui/material'
import { useSnackbar } from 'notistack'
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer'
import useSpace from '../../hooks/useSpace'
import useUser from '../../hooks/useUser'
// components
import Logo from '../../components/Logo'
import Scrollbar from '../../components/Scrollbar'
import NavSection from '../../components/NavSection'
import { SpacesModal, SpaceManager, CreateSpace } from '../../components/spaces'
//
import { MHidden } from '../../components/@material-extend'
import { adminSidebarConfig, memberSidebarConfig } from './SidebarConfig'
// routes
import { PATH_SECURED } from '../../routes/paths'
// utils
import { saveValue, KEYS } from '../../utils/handleLocalStorage'
import { LINKS } from '../../utils/constants'
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280
const COLLAPSE_WIDTH = 102
const SUCCESS_MESSAGE = 'Space Successfully Deleted'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}))

const SpaceButtonStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}))

const SpaceName = styled(Typography)(() => ({
  width: 210,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
}))

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation()
  const [isSpacesModalOpen, setIsSpacesModalOpen] = useState(false)
  const [isSpaceManagerOpen, setIsSpaceManagerOpen] = useState(false)
  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = useState(false)
  const [manageSpace, setManageSpace] = useState({})
  const [toggleScreenShare, setToggleScreenShare] = useState(false)
  const [deleteSpaceOpen, setDeleteSpaceOpen] = useState(false)
  const navigate = useNavigate()

  const {
    load: loadSpace,
    data: space,
    spaceData: selectedSpaceData,
    updateSpaceName: updateSpace,
    createSpace,
    deleteSpace,
    getSpaceData
  } = useSpace()

  const { name } = space

  const isShareScreen = selectedSpaceData.feature_ids?.includes(3)

  const { data: user } = useUser()

  const { spaces } = user

  const { enqueueSnackbar } = useSnackbar()
  const [spaceName, setSpaceName] = useState('')
  const { isCollapse, collapseClick, collapseHover, onHoverEnter, onHoverLeave } = useCollapseDrawer()
  const handleOpenModal = () => {
    if (pathname !== '/rooms/create' && !pathname.includes('edit')) {
      setIsSpacesModalOpen(true)
    }
  }

  const handleOpenManager = async (space) => {
    await getSpaceData(space.id)
    setManageSpace(space)
    setIsSpacesModalOpen(false)
    setIsSpaceManagerOpen(true)
  }

  const handleOpenCreateSpace = () => {
    if (isSpacesModalOpen) {
      setIsSpacesModalOpen(false)
    }
    setIsCreateSpaceOpen(true)
    setToggleScreenShare(false)
  }

  const handleSpacesModalClose = () => {
    setIsSpacesModalOpen(false)
  }

  const handleSpaceManagerClose = () => {
    setIsSpaceManagerOpen(false)
  }

  const handleCreateSpaceClose = () => {
    setIsCreateSpaceOpen(false)
    setToggleScreenShare()
    setSpaceName('')
  }

  const handleLoadSpace = (spaceId) => {
    // If user already selects current space there's no need to reload it
    if (space.id !== spaceId) {
      saveValue(KEYS.SPACE_ID, spaceId)
      loadSpace(spaceId)
    }
    handleSpacesModalClose()
    navigate(PATH_SECURED.home)
  }

  const handleDisable = async () => {
    await deleteSpace(manageSpace.id, user.id)
    enqueueSnackbar(SUCCESS_MESSAGE, { variant: 'success' })
    setIsSpaceManagerOpen(false)
    setDeleteSpaceOpen(false)
  }

  const handleSpaceUpdate = (space) => {
    const featureID = []
    if (toggleScreenShare) {
      featureID.push(3)
    }
    space.feature_ids = featureID
    updateSpace(space, user.id)
    setIsSpaceManagerOpen(false)
  }

  const handleCreateSpaceSave = (space) => {
    const featureID = []
    if (toggleScreenShare) {
      featureID.push(3)
    }
    space.feature_ids = featureID
    createSpace(space, user.id)
    setIsCreateSpaceOpen(false)
    setToggleScreenShare()
    setSpaceName('')
  }
  const handleShareScreen = ({ target }) => {
    setToggleScreenShare(!target.checked)
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    if (selectedSpaceData) {
      setToggleScreenShare(isShareScreen)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpaceData])

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo alt="Twine logo" />
          </Box>
        </Stack>

        {!isCollapse && user.isAdmin && (
          <>
            <Link id="viewAllSpaces" underline="none" component={RouterLink} to="#" onClick={handleOpenModal}>
              <SpaceButtonStyle>
                <SpaceName variant="subtitle2" sx={{ color: 'text.primary' }} title={name}>
                  {name}
                </SpaceName>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  View all spaces
                </Typography>
              </SpaceButtonStyle>
            </Link>
          </>
        )}
      </Stack>

      <NavSection navConfig={user.isAdmin ? adminSidebarConfig : memberSidebarConfig} isShow={!isCollapse} />

      {!isCollapse && (
        <Stack alignItems="center" sx={{ px: 2, pb: 2, mt: 2, width: 1, textAlign: 'center' }}>
          <Box sx={{ width: 1 }} />

          <img src="/static/home/illustrations-illustration-docs.png" alt="login" />

          <div>
            <Typography gutterBottom variant="subtitle1" sx={{ mt: 2 }}>
              Hi, {user?.first_name}
            </Typography>
            {user.isAdmin ? (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Need help?
                <br /> Please check our docs
              </Typography>
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Want to host your own conversations?
              </Typography>
            )}
          </div>
          {user.isAdmin ? (
            <Button
              href={LINKS.SUPPORT_DOCUMENTATION}
              sx={{ mt: 2, boxShadow: 'none' }}
              target="_blank"
              variant="contained"
            >
              Documentation
            </Button>
          ) : (
            <Button variant="contained" onClick={handleOpenCreateSpace} sx={{ marginTop: 2, boxShadow: 'none' }}>
              Become a host
            </Button>
          )}
        </Stack>
      )}
    </Scrollbar>
  )

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
      <SpacesModal
        open={isSpacesModalOpen}
        onClose={handleSpacesModalClose}
        spaces={spaces}
        loadSpace={handleLoadSpace}
        onOpenManager={handleOpenManager}
        onOpenCreateSpace={handleOpenCreateSpace}
      />
      <SpaceManager
        open={isSpaceManagerOpen}
        onClose={handleSpaceManagerClose}
        space={manageSpace}
        onDisable={handleDisable}
        onSave={handleSpaceUpdate}
        toggleScreenShare={toggleScreenShare}
        isShareScreen={isShareScreen}
        onToggle={handleShareScreen}
        deleteSpaceOpen={deleteSpaceOpen}
        setDeleteSpaceOpen={setDeleteSpaceOpen}
      />
      <CreateSpace
        open={isCreateSpaceOpen}
        onClose={handleCreateSpaceClose}
        onSave={handleCreateSpaceSave}
        toggleScreenShare={toggleScreenShare}
        onToggle={handleShareScreen}
        setSpaceName={setSpaceName}
        spaceName={spaceName}
      />
    </RootStyle>
  )
}
