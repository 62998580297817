import { createSlice } from '@reduxjs/toolkit'
// services
import { createSpaceTopic, getSpaceTopics, updateSpaceTopic } from '../../services/space'
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {}
}

const slice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getTopicsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getTopics(spaceId) {
  const { startLoading, hasError, getTopicsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getSpaceTopics(spaceId)
      dispatch(getTopicsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function createTopic(spaceID, topicValue) {
  const { hasError } = slice.actions
  let step = null
  return async (dispatch) => {
    try {
      const topicsInfo = {
        topic: topicValue.topicName
      }
      step = 'Sending topic info to endpoint'
      await createSpaceTopic(spaceID, topicsInfo)
      dispatch(getTopics(spaceID))
    } catch (error) {
      log.error({ step, topicValue, error })
      dispatch(hasError(error))
    }
  }
}

export function updateTopic(spaceID, topicValue) {
  const { hasError } = slice.actions
  let step = null
  return async (dispatch) => {
    try {
      const topicsInfo = {
        topic: topicValue.topicName,
        is_disabled: topicValue?.is_disabled
      }
      const topicId = topicValue.id
      step = 'Sending topic info to endpoint'
      await updateSpaceTopic(spaceID, topicId, topicsInfo)
      dispatch(getTopics(spaceID))
    } catch (error) {
      log.error({ step, topicValue, error })
      dispatch(hasError(error))
    }
  }
}
