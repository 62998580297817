import { createSlice } from '@reduxjs/toolkit'
// services
import {
  getRoomAnalytics,
  getRoomAnalyticsTotalParticipants,
  getRoomAnalyticsTotalNoOfConvos,
  getRoomAnalyticsTotalMatches,
  getRoomAnalyticsNpsScore,
  getRoomAnalyticsJoinOverTime,
  getRoomAnalyticsTotalConnections,
  getRoomAnalyticsTotalMinutesOfConvo,
  getRoomAnalyticsAvgMatchesPerUser,
  getRoomAnalyticsAvgTimeInConvo,
  getRoomAnalyticsConversationPartners,
  getRoomAnalyticsLeaderboard
} from '../../services/rooms'
// utils
import { log } from '../../utils/logger'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isSuccess: false,
  error: false,
  data: {},
  totalParticipantsData: {},
  totalNoOfConvosData: {},
  totalMatchesData: {},
  npsScoreData: {},
  totalConnectionsData: {},
  totalMinutesOfConvoData: {},
  avgMatchesPerUserData: {},
  avgTimeInConvoData: {},
  conversationPartnersData: [],
  leaderboardData: []
}

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isSuccess = false
      state.isLoading = true
    },

    // HAS ERROR -> STORE ERROR
    hasError(state, action) {
      state.isLoading = false
      state.isSuccess = false
      state.error = action.payload
    },

    // SUCCESS -> STORE DATA
    getSpaceAnalyticsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.data = action.payload
    },

    getTotalParticipantsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.totalParticipantsData = action.payload
    },

    getTotalNoOfConvosSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.totalNoOfConvosData = action.payload
    },

    getTotalMatchesSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.totalMatchesData = action.payload
    },

    getNpsScoreSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.npsScoreData = action.payload
    },

    getTotalConnectionsSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.totalConnectionsData = action.payload
    },

    getTotalMinutesOfConvoSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.totalMinutesOfConvoData = action.payload
    },

    getAvgMatchesPerUserSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.avgMatchesPerUserData = action.payload
    },

    getAvgTimeInConvoSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.avgTimeInConvoData = action.payload
    },

    getConversationPartnersSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.conversationPartnersData = action.payload
    },

    getRoomAnalyticsLeaderboardSuccess(state, action) {
      state.isLoading = false
      state.isSuccess = true
      state.leaderboardData = action.payload
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export function getAnalytics(roomId) {
  const { startLoading, hasError, getSpaceAnalyticsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalytics(roomId)
      dispatch(getSpaceAnalyticsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getTotalParticipants(roomId) {
  const { startLoading, hasError, getTotalParticipantsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsTotalParticipants(roomId)
      dispatch(getTotalParticipantsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getTotalNoOfConvos(roomId) {
  const { startLoading, hasError, getTotalNoOfConvosSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsTotalNoOfConvos(roomId)
      dispatch(getTotalNoOfConvosSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getTotalMatches(roomId) {
  const { startLoading, hasError, getTotalMatchesSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsTotalMatches(roomId)
      dispatch(getTotalMatchesSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getNpsScore(roomId) {
  const { startLoading, hasError, getNpsScoreSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsNpsScore(roomId)
      dispatch(getNpsScoreSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getTotalConnections(roomId) {
  const { startLoading, hasError, getTotalConnectionsSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsTotalConnections(roomId)
      dispatch(getTotalConnectionsSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getTotalMinutesOfConvo(roomId) {
  const { startLoading, hasError, getTotalMinutesOfConvoSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsTotalMinutesOfConvo(roomId)
      dispatch(getTotalMinutesOfConvoSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getAvgMatchesPerUser(roomId) {
  const { startLoading, hasError, getAvgMatchesPerUserSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsAvgMatchesPerUser(roomId)
      dispatch(getAvgMatchesPerUserSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getAvgTimeInConvo(roomId) {
  const { startLoading, hasError, getAvgTimeInConvoSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsAvgTimeInConvo(roomId)
      dispatch(getAvgTimeInConvoSuccess(data.response_objects))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getConversationPartners(roomId) {
  const { startLoading, hasError, getConversationPartnersSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsConversationPartners(roomId)
      dispatch(getConversationPartnersSuccess(data.response_objects || []))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}

export function getLeaderboard(roomId) {
  const { startLoading, hasError, getRoomAnalyticsLeaderboardSuccess } = slice.actions
  return async (dispatch) => {
    dispatch(startLoading())
    try {
      const data = await getRoomAnalyticsLeaderboard(roomId)
      dispatch(getRoomAnalyticsLeaderboardSuccess(data.response_objects || []))
    } catch (error) {
      log.error(error)
      dispatch(hasError(error))
    }
  }
}
