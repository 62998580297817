import PropTypes from 'prop-types'
import { TablePagination } from '@mui/material'

// ----------------------------------------------------------------------

ListFooter.propTypes = {
  records: PropTypes.array,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  spaceRowsPerPageOptions: PropTypes.array
}

export default function ListFooter({
  records,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  spaceRowsPerPageOptions
}) {
  return (
    <TablePagination
      rowsPerPageOptions={spaceRowsPerPageOptions || [5, 10, 25]}
      component="div"
      count={records.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
    />
  )
}
