import PropTypes from 'prop-types'
// material
import { styled } from '@mui/material/styles'
import { Stack, Divider, Button, Typography, Alert, Box, Switch, OutlinedInput } from '@mui/material'
import { useTheme } from '@mui/styles'
// components
import { DialogAnimate } from '../animate'

const HEADER_TEXT = {
  newSpaceHeader: 'New space',
  createSpaceText: 'Create a space for your company, separate teams or events.'
}

const SPACE_ERROR_MESSAGE = {
  spaceErrorAlert: 'Name length must be less than or equal to 256 characters long'
}

const SPACE_CONTROL_TEXT = {
  spaceControl: 'Space controls',
  appliesToAllRooms: 'Applies to all rooms in this space.',
  allowShareScreen: 'Allow guests to share their screen'
}

CreateSpace.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  toggleScreenShare: PropTypes.bool,
  onToggle: PropTypes.func,
  spaceName: PropTypes.any,
  setSpaceName: PropTypes.func
}

const HeaderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(1),
  paddingBottom: '0'
}))

const TitleStyle = styled('span')(({ theme }) => ({
  marginBottom: '4px',
  fontWeight: 'bold',
  color: theme.palette.grey[800],
  fontSize: '18px'
}))

const SubtitleStyle = styled('span')(({ theme }) => ({
  color: theme.palette.grey[600],
  fontSize: theme.typography.pxToRem(14),
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightRegular
}))

const Header = () => (
  <HeaderStyle>
    <TitleStyle>{HEADER_TEXT.newSpaceHeader}</TitleStyle>
    <SubtitleStyle>{HEADER_TEXT.createSpaceText}</SubtitleStyle>
  </HeaderStyle>
)

const MAX_LENGTH = 256

export default function CreateSpace({ open, onClose, onSave, toggleScreenShare, onToggle, spaceName, setSpaceName }) {
  const theme = useTheme()

  const CustomButton = styled(Button)`
    &.Mui-disabled {
      background-color: ${theme.palette.buttons.defaultBackground};
      color: ${theme.palette.buttons.defaultText};
      opacity: 0.5;
    }
  `

  const handleClose = () => {
    onClose()
  }
  const handleChange = (e) => {
    setSpaceName(e.target.value)
  }

  const handleSave = () => {
    onSave({ name: spaceName })
  }
  return (
    <DialogAnimate open={open} onClose={onClose} fullWidth maxWidth="md">
      <Stack>
        <Stack display="flex" flexDirection="row">
          <Stack width="50%">
            <Stack pl={2} mt={2}>
              <Header />
            </Stack>
            <Stack sx={{ marginTop: '24px', marginLeft: '24px' }} width={430}>
              <OutlinedInput
                sx={{ width: 387, fontWeight: 600 }}
                value={spaceName}
                placeholder="Give your space a name..."
                onChange={handleChange}
              />
            </Stack>
            {spaceName.length > MAX_LENGTH && (
              <Box width={379} sx={{ position: 'absolute', top: 200, left: 25 }}>
                <Alert severity="error">{SPACE_ERROR_MESSAGE.spaceErrorAlert}</Alert>
              </Box>
            )}
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ height: '404px' }} />
          <Stack
            pl={2}
            mt={2}
            width="50%"
            style={{
              flex: 1,
              fontSize: '16px'
            }}
          >
            <HeaderStyle>
              <TitleStyle>{SPACE_CONTROL_TEXT.spaceControl}</TitleStyle>
              <SubtitleStyle>{SPACE_CONTROL_TEXT.appliesToAllRooms}</SubtitleStyle>
            </HeaderStyle>
            <Stack pl={1}>
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginTop: '19px' }}>
                <Typography
                  sx={
                    toggleScreenShare
                      ? { opacity: 0.5, color: theme.palette.grey[800] }
                      : { color: theme.palette.grey[800] }
                  }
                  variant="subtitle1"
                >
                  {SPACE_CONTROL_TEXT.allowShareScreen}
                </Typography>
                <Switch
                  id="toggleScreenShare"
                  sx={{ marginRight: '24px' }}
                  checked={!toggleScreenShare}
                  onChange={onToggle}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack>
          <Divider />
          <Stack pt={2} px={3} pb={2} direction="row" justifyContent="end">
            <Button
              id="cancelCreateSpaceButton"
              sx={{
                color: theme.palette.grey[800],
                border: theme.palette.buttons.doneBorder
              }}
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <CustomButton
              id="createSpaceButton"
              onClick={handleSave}
              variant="contained"
              disabled={!spaceName?.length || spaceName.length > MAX_LENGTH}
              sx={{
                color: theme.palette.secondary,
                border: theme.palette.buttons.doneBorder,
                boxShadow: 'none',
                marginLeft: '24px'
              }}
            >
              Create
            </CustomButton>
          </Stack>
        </Stack>
      </Stack>
    </DialogAnimate>
  )
}
