// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

export default function GoogleIconWithText({ ...other }) {
  const { text } = other
  delete other.text
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" {...other}>
      <svg width="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <title>Google sign in</title>
        <g fill="none">
          <path
            d="M6.182 12.213c0-.649.108-1.27.3-1.854l-3.366-2.57a9.981 9.981 0 0 0-1.025 4.424c0 1.59.369 3.09 1.024 4.42l3.364-2.575c-.19-.58-.297-1.2-.297-1.845"
            fill="#FBBC05"
          />
          <path
            d="M12.09 6.312c1.41 0 2.683.5 3.683 1.316l2.909-2.905c-1.773-1.543-4.046-2.496-6.591-2.496a9.97 9.97 0 0 0-8.975 5.562l3.366 2.57a5.893 5.893 0 0 1 5.609-4.047"
            fill="#EB4335"
          />
          <path
            d="M12.09 18.113a5.893 5.893 0 0 1-5.608-4.047l-3.366 2.57a9.97 9.97 0 0 0 8.975 5.563c2.439 0 4.768-.866 6.515-2.49l-3.194-2.469c-.902.568-2.037.873-3.321.873"
            fill="#34A853"
          />
          <path
            d="M21.636 12.213c0-.59-.09-1.226-.227-1.816h-9.318v3.858h5.364c-.269 1.316-.999 2.327-2.043 2.985l3.194 2.47c1.836-1.704 3.03-4.242 3.03-7.497"
            fill="#4285F4"
          />
        </g>
      </svg>
      <Box sx={{ whiteSpace: 'nowrap', ml: 1 }}>{text}</Box>
    </Box>
  )
}
