// routes
import { PATH_SECURED } from '../../routes/paths'
// components
import SvgIconStyle from '../../components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
)

const ICONS = {
  user: getIcon('ic_user'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  rooms: getIcon('ic_components'),
  home: getIcon('ic_home'),
  connections: getIcon('ic_user_group')
}

export const adminSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Home', path: PATH_SECURED.home, icon: ICONS.home },
      { title: 'Dashboard', path: PATH_SECURED.dashboard, icon: ICONS.dashboard },
      { title: 'Rooms', path: PATH_SECURED.rooms, icon: ICONS.rooms },
      { title: 'Insights', path: PATH_SECURED.insights, icon: ICONS.analytics },
      { title: 'Members', path: PATH_SECURED.members, icon: ICONS.user }
    ]
  },

  // PERSONAL
  // ----------------------------------------------------------------------
  {
    subheader: 'personal',
    items: [
      { title: 'Profile', path: PATH_SECURED.profile, icon: ICONS.user },
      { title: 'Connections', path: PATH_SECURED.connections, icon: ICONS.connections }
    ]
  }
]

export const memberSidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Home', path: PATH_SECURED.home, icon: ICONS.home },
      { title: 'Profile', path: PATH_SECURED.profile, icon: ICONS.user },
      { title: 'Connections', path: PATH_SECURED.connections, icon: ICONS.connections }
    ]
  }
]
